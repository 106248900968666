<template>
	<div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header">
        <h1>New Order</h1>
        <button class="btn btn__flat" @click="goBack"><i class="fas fa-arrow-left fa-2x"></i></button>
      </div>
      <form ref="form" @submit.prevent>
      	<div class="dashboard__container--body">
      		<div class="dashboard__container--body--col">
            <div>
              <h4>Order Details</h4>
      				<div class="mb-3">
      					<label for="eventName">Event/Job Title (optional):</label>
      					<input type="text" v-model.trim="event.title" id="eventName" required />
      				</div>
              <div class="mb-3">
                <label for="eventRef">Client PO/Reference Number:</label>
                <input type="text" v-model.trim="event.refNum" id="eventRef" />
              </div>
              <div class="mb-3">
                <span class="caption strong">Job Number: {{eventsTotalNext}}</span>
              </div>
              <div class="mb-3" v-if="venues && venues.length > 1">
                <label for="venue">Venue:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="venues"
                  v-model="event.venue"
                  >
                </v-select>
              </div>
              <div class="mb-3" v-if="clients.length >= 1">
                <label for="client">Client:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="clients"
                  multiple
                  v-model="event.client"
                  >
                </v-select>
              </div>
              <div class="mb-3" v-if="jobss.length >= 1">
              <label for="pickDate">Choose Jobs:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="jobss"
                  multiple
                  v-model="event.jobs"
                  taggable
                  push-tags
                  >
                </v-select>
              </div>
            </div>
          </div>
          <div class="dashboard__container--body--col">
            <div>
            
            <div class="mb-3" v-if="mgrs.length >= 1">
            <label for="pickDate">Staffing Managers:</label>
              <v-select
                class="mt-2"
                label="name" 
                :options="mgrs"
                multiple
                v-model="event.mgrs"
                >
              </v-select>
            </div>
            <div class="mb-3" v-if="(groups && groups.length >= 1)">
            <label for="groups">Restrict to Groups:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="groups"
                v-model="event.groups"
                multiple
                >
              </v-select>
            </div>

            <div class="mb-3">
            <label for="types">Worker Type:</label>
              <v-select
                class="mt-2"
                label="title" 
                :options="types"
                v-model="event.workerType"
                :clearable="false"
                >
              </v-select>
            </div>
            <div class="mb-3">
              <label for="mgrs">Order Type:</label>
                <v-select
                  class="mt-2"
                  label="title" 
                  :options="typess"
                  v-model="event.shiftType"
                  :clearable="false"
                  >
                </v-select>
              </div>
              <div class="mb-3">
                <label for="date mb-2">Start/Order Date:</label>
                <!-- <label for="Date">Date:</label> -->
                <input type="date" v-model.trim="event.startDate"  id="Date"  />
              </div>
        
            
            </div>
          </div>
        </div>
      
        <div class="floating_buttons"  style="background: transparent;" v-if="valid">
          <button class="btn btn__primary btn__large" @click="addEvent()">
            Submit Order
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
    		</div>      
      </form>
    </div>
	</div>
</template>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import { VueEditor } from "vue2-editor";
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'addevent',
  data: () => ({
    event: {
      days:[],
      jobs:[],
      visible: true,
      status: 'Open',
      groups: [],
      workerType: 'Contractors',
      sendHireNotifications: true,
      sendShiftAssignmentNotifications: true
    },
    typess: ['Gig', 'Job'],
    croppa: {},
    day:'',
    job:'',
    multiDay: false,
    performingRequest: false,
    imageData: null,
    fileTitle: '',
    fileDescription: '',
    uploadValue: 0,
    showBar:false,
    types: ['Contractors', 'Employees']
  }),
  components: {
    VueEditor,
    Loader
  },
  created () {
    if (!this.venues || this.venues.length < 1) {
      this.$store.dispatch("getVenues")
    }
    if (!this.clients || this.clients.length < 1) {
      this.$store.dispatch("getClients")
    }
    if (!this.jobs || this.jobs.length < 1) {
      this.$store.dispatch("getJobsState")
    }
    if (!this.mgrs || this.mgrs.length < 1) {
      this.$store.dispatch("getMgrsState")
    }
    if (!this.tags || this.tags.length < 1) {
      this.$store.dispatch("getTagsState")
    }
    if (!this.groups || this.groups.length < 1) {
      this.$store.dispatch("getGroups")
    }
    this.$store.dispatch("getEventsTotal")
  },
  computed: {
    ...mapState(['venues', 'clients', 'jobs', 'mgrs', 'tags', 'groups', 'eventsTotalNext']),
    backgroundUrl() {
      return this.event.photoUrl || 'https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/stadium.png?alt=media&token=89f2362c-d1bc-4338-a837-fad1d664c51d'
    },
    jobss() {
      return this.jobs
    },
    year() {
      return moment(this.event.startDate).format('YYYY')
    },
    month() {
      return moment(this.event.startDate).format('M')
    },
    dday() {
      return moment(this.event.startDate).format('D')
    },
    slug() {
      if (this.event.title && this.year) {
        return this.event.title.replace(/[^\w ]+/g,'').replace(/ +/g,'-').toLowerCase() + `-` + this.month + `-` + this.dday + `-` + this.year
      }
    },
    valid() {
      if (this.event && this.event.title && this.event.client && this.event.jobs && this.event.shiftType && this.event.startDate && this.event.mgrs) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    previewImage(event) {
      this.uploadValue=0;
      this.imageData=event.target.files[0]
    },
    onUploadFile() {
      this.showBar = true
      let event = this.event
      let fileTitle = this.fileTitle
      let fileDescription = this.fileDescription
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
            var docRef = fb.eventsCollection.doc(event.id)
            docRef.update({
              files: fb.firestore.FieldValue.arrayUnion({
                title: fileTitle,
                description: fileDescription,
                url: downloadURL
              })
            });
            event.files.push({
              title: fileTitle,
              description: fileDescription,
              url: downloadURL
            })
        })
        this.showBar = false
      })
      this.imageData = null
      this.fileTitle = ''
      this.fileDescription = ''
      this.$refs.fileInputTip.value=null
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
    formatDate(q) {
      if(q) {
        console.log(q)
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM')
      } else {
        return null
      }
    },
    addDay() {
      let event = this.event
      let day = this.day
      event.days.push(day)
      day = ''
      // this.$store.dispatch('updateEvent', event)
    },
    addJob() {
      let event = this.event
      event.jobs.push(this.job)
      this.job = ''
      // this.$store.dispatch('updateEvent', event)
    },
    deleteUploadedFile(u, index) {
      console.log(u)
      let event = this.event
      event.files.splice(index, 1)
      // this.$store.dispatch('updateEvent', event)
    },
    deleteDay(index) {
      let event = this.event
      event.days.splice(index, 1)
      // this.$store.dispatch('updateEvent', event)
    },
    deleteJob(index) {
      let event = this.event
      event.jobs.splice(index, 1)
      // this.$store.dispatch('updateEvent', event)
    },
    addEvent() {
      this.performingRequest = true
    	let event = this.event
      let croppa = this.croppa
      let store = this.$store
      let slug = this.slug
      event.jobNum = this.eventsTotalNext
      event.title = (this.event.title || this.eventsTotalNext)
      console.log(event)
      event.slug = slug
      // store.dispatch('addEvent', event)

      fb.eventsCollection.add(event)
      .then(
        doc => {
          let geo = new fb.firestore.GeoPoint(event.venue.center.lat, event.venue.center.lng)
          fb.eventsCollection.doc(doc.id).update({
            id: doc.id,
            created: fb.firestore.FieldValue.serverTimestamp(),
            venueId: event.venue.id,
            jobs: event.jobs || [],
            days: event.days || [],
            event_city: event.venue.address.city,
            event_location: geo,
            event_state: event.venue.address.state,
            venue_title: event.venue.title,
            event_zip: event.venue.address.zip,
            endDate: event.endDate || event.startDate
          })
          setTimeout(() => {
            this.performingRequest = false
            event = {}
            let url = `/orders/` + doc.id
            router.push(url)
          }, 2000)
        }
      )
    },
    goBack() {
      router.go(-1)
    },
  },
  beforeDestroy () {
    this.$store.dispatch('clearGroups')
    this.$store.dispatch('clearClientsState')
    this.$store.dispatch('clearVenuesState')
    this.$store.dispatch('clearJobsState')
    this.$store.dispatch('clearTagsState')
    this.showBar = null
    this.uploadValue = null
    this.job = null
    this.event = {}
    this.croppa = null
    this.day = null
    this.multiDay = null
    this.performingRequest = null
    delete this.day
    delete this.showBar
    delete this.uploadValue
    this.fileDescription = null
    delete this.fileDescription
    this.fileTitle = null
    delete this.fileTitle
    delete this.event
    delete this.job
    this.imageData = null
    delete this.imageData
    delete this.croppa
    delete this.multiDay
    this.performingRequest = null
    delete this.performingRequest
    this.types = null
    delete this.types
    this.typess = null
    delete this.typess
    this.event = null
    delete this.event
    delete this.previewImage
    delete this.onUploadFile
    delete this.onFileTypeMismatch
    delete this.onFileSizeExceed
    delete this.formatDate
    delete this.addDay
    delete this.addJob
    delete this.deleteUploadedFile
    delete this.deleteDay
    delete this.deleteJob
    delete this.addEvent
    delete this.goBack
    delete this.eventsTotalNext
  }
}
</script>